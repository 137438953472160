<template>
    <div class="points">
 
      <!-- 綁定郵箱找回 -->

        <s-header :name="$t('LAN.points')"></s-header>
        
        <!-- 头部 -->
        <div class="cur-points"  >
            <span>{{ $t("LAN.current_points") }}</span>
            <img src="@/assets/common/points.png" />
            <span>{{ points }}</span>
        </div>

        <!-- 选择充值金额 -->
        <div class="pay-select" style="height: 98vh;">
            <p class="select-tips">
                <span>{{ $t("LAN.select_amount") }}</span>
            </p>
            <div class="pay-box">
                <van-grid direction="horizontal" :column-num="3" :gutter="10">
                    <van-grid-item
                        v-for="(pay, index) in payMenus"
                        :key="index"
                        @click="onSelectPayItem(pay)"
                    >
                        <a
                            class="pay-box-item"
                            :class="{
                                'pay-box-item-select': selectId == pay.id,
                            }"
                        >
                            <div>
                                <span>{{
                                    formate(
                                        $t("LAN.pay_point"),
                                        pay.point,
                                        pay.give
                                    )
                                }}</span>
                            </div>
                            <p>{{ getAmount(pay.amount) }}</p>
                        </a>
                    </van-grid-item>
                </van-grid>
                 <!--     <div style="margin: 20px;">
                    <van-button round block color="#6e87ea" @click="onPay">{{$t('LAN.pay')}}</van-button>
                </div> 
         <div style="margin: 20px;">
                    <van-button round block color="#6e87ea" @click="onInstall">{{$t('LAN.install')}}</van-button>
                </div>  --> 
                        <!-- 按钮区域 -->
            <div v-if="buttonShow" style="margin: 20px; margin-top: 60px">
                <van-button round block color="#d4d5d9" @click="onSetting">{{
                    $t("LAN.pay_setting")
                }}</van-button>
            </div>
            <div v-if="buttonShow" style="margin: 20px; margin-bottom: 60px">
                <van-button
                    round
                    block
                    color="#d4d5d9"
                    v-if="payPasswordFlag"
                    @click="goTo('payForgot')"
                    >{{ $t("LAN.forget_pay") }}</van-button
                >
            </div>
                

            </div> 
        </div>


        <van-dialog  v-model:show="show" :showConfirmButton="false" :title="$t('LAN.pay_select_mode')" show-cancel-button>
            <div style="white-space:normal;">
                <div>
                    <div class="content">
                      <a href="javascript:;" style="width: 92%; height: 1rem; font-size: 0.36rem;display: block; background: #333333; display: flex; justify-content: center; align-items: center; border-radius: 0.1rem; color: #f3d8a9; margin-top:15px; margin-left: 12px;" @click="rests" id="otherPayBtn">其他支付方式(优惠10%)</a>
                      <a href="javascript:;" style="width: 92%; height: 1rem; font-size: 0.36rem;display: block; background: #b5b3b3; display: flex; justify-content: center; align-items: center; border-radius: 0.1rem; color: #606060;  margin-top:15px;  margin-left: 12px;" id="officialPayBtn">官方支付</a>
                     </div>
                </div>
            </div>
        </van-dialog>


        
        <!-- 自定义弹框 -->
        <van-dialog  v-model:show="custom" :showConfirmButton="true" @confirm="confirm" title="请输入自定义金额" >
            <div style="white-space:normal;">
                <div>
                    <div class="content">
                        <van-cell-group inset>
                            <van-field v-model="customAmount" label="金额" placeholder="请输入自定义金额" />
                        </van-cell-group>
                     </div>
                </div>
            </div>
        </van-dialog>

    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast, Dialog } from "vant";
import { useI18n } from "vue-i18n";
import { formate } from "@/utils/string";
//import { openWebView } from "@/utils/common";
import { requestPointsInfo, requestPayMode ,gameConfig} from "@/service/pay";
import { POINTS_PAY_DATA } from "@/configs/global";
import { requestExchangeRate } from "@/service/exchange";
import axios from "axios";
import sHeader from '@/components/Header';
import layer from "layui-layer";

export default {
    setup() {
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();
        const state = reactive({
            selectId: 0,
            payMenus: [
               
            ],

            forget: false,
            points: 0,
            email: "",
            payPasswordFlag: false,
            forgetColumns: [
                t("LAN.forgot_from_email"),
                t("LAN.forgot_from_service"),
            ],
            payFale:0,   
            currencyValue: "CNY",
            buttonShow:true,
            show:false,
            custom:false,
            customAmount: 0,
            params:undefined,
            resu:undefined
        });
        onMounted(() => {
            init();
        });

        const init = async () => {
            //沙盒模式
            if(store.state.app.sandbox == 1){

                state.payMenus  = [
                    {
                        "id": "0",
                        "point": "1",
                        "amount": 0.01,
                        "give": "0",
                        "productId": "com.hz.points.0.01"
                    },
                    {
                        "id": "1",
                        "point": "1",
                        "amount": 0,
                        "give": "0",
                        "productId": "com.hz.points.0"
                    }
                ]
            }else{
                //读取本地文件遍历显示    
                let resultData = await axios.get("/data/iap/iap.json" );
                state.payMenus = resultData.data;
            }
    
            Toast.loading(t("LAN.loading"));
            let result = await requestPointsInfo();
            store.dispatch("updatePoints", result);
            Toast.clear();
   
            state.payPasswordFlag = store.state.user.payPasswordFlag || false;
            state.points = store.state.user.points || 0;
            state.email = store.state.user.email;
            state.currencyValue = store.state.user.currency;
            const exchangeResult = await requestExchangeRate( state.currencyValue  );
            state.currencyRate = exchangeResult.rate;
            let appId = store.state.app.appId;
            let gameResult = await gameConfig(appId);
            //控制按钮是否显示
            state.buttonShow = gameResult.payFale == 0 ? true :false;
            //游戏开启密码支付
            state.payFale = gameResult.payFale == 0 ?  result.payPasswordFlag : gameResult.payFale
   
     
        };

        const getAmount = (cnyAmount) => {
            return (state.currencyValue + " " + (cnyAmount * state.currencyRate).toFixed(2) );
        };
        const rests  = async ()=>{
            //跳转支付
            payJump(state.params,state.resu);
            //跳转到混合页面
            state.show = false;
        }
        const onPay = async () => {
            if (state.payFale == 1 ) {
                let payData = state.payMenus[state.selectId];
                const params = {
                    //游戏id固定1040
                    gameId: POINTS_PAY_DATA.gameId,
                    channel: 1,
                    lang: store.getters.lang,
                    token: encodeURI(store.getters.token),
                    userId: store.getters.userId,
                    serId: store.getters.roleServerId,
                    serName: encodeURI(store.getters.roleServerName),
                    roleId: store.getters.roleId,
                    rolename: encodeURI(store.getters.roleName),
                    gameName: encodeURI(store.getters.gameName),
                    vip: encodeURI(store.getters.roleVip),
                    roleLevel: store.getters.roleLevel,
                    currency: store.getters.currency,
                    userName: encodeURI(store.getters.username),
                    password: store.getters.password,
                    sandbox: store.state.app.sandbox,    
                    amount: payData.amount,
                    outOrderNo: encodeURI(payData.point + payData.give),
                    productId: encodeURI(payData.productId),
                    points: state.points,
                };
                state.params = params;
                Toast.loading(t("LAN.pay_paying"));
                const result = await requestPayMode({
                    gameId: POINTS_PAY_DATA.gameId,
                    level: store.getters.roleLevel,
                    channel: 1,
                });
                state.resu = result;
                Toast.clear();

                //根据支付开关进行对应切换
                if(result.official == 0){
                    console.log("跳转到官网支付")
                    return;
                 }
                if(result.official == 2){
                    //跳转到混合页面
                    state.show = true;
                    return;
                 }
                 //跳转支付
                 payJump(params,result);
            } else {
                Toast.fail(t("LAN.unsetting"));
                goTo("payPassword");
            }
        };

        const payJump = (params,result)=>{
            params["mode"] = encodeURI(result.mode);
            params["discount"] = encodeURI(result.discount);

            Dialog.confirm({
                    cancelButtonText: t("LAN.pay_cancel"),
                    confirmButtonText: t("LAN.pay_complete"),
                    title: t("LAN.pay_new_tips"),
                    message: t("LAN.pay_new_message"),
                    closeOnClickOverlay: true,
                    confirmButtonColor: "#6e87ea",
            }).then(async () => {
                init();
            }).catch(() => {});
            var url = '/payCenter/pay?'

                        
            Object.keys(params).forEach(function(key) {
                url = url+ key + "=" + params[key] +"&";
            });
            console.log(document.body.clientWidth)
            var width = '90%';
            if(document.body.clientWidth >= 640){
                width =  '13.45894rem';
            }
            layer.open({
                type: 2,
                title: t("LAN.pay_zx"),
                shadeClose: true,
                anim: -1,
                shade: 0.8,
                skin: 'layui-class',
                area: [width, '90%'],
                content: url
           });

          //  openWebView(
           //     document.location.origin + "/payCenter/pay",
            //    params,
                ""
          //  );
        }

        const onSelectPayItem = (pay) => {
            if(pay.amount == 0){
                state.custom = true;
                return;
            }
            state.selectId = pay.id;
            onPay();
        };

        const onSetting = async () => {
            if (state.email != "") {
                goTo("payPassword", { payPasswordFlag: state.payPasswordFlag });
            } else {
                Toast.fail(t("LAN.email_unbound"));
                goTo("bindEmail");
            }
        };

        const goTo = (name, params) => {
            router.push({ name: name, query: params });
        };
        const goBack = () => {
            router.go(-1)
        }
        const onInstall = () => {
            goTo("install",{
                payFale:state.payFale
            });
        }
        const confirm = ()=>{
            if(state.customAmount != 0){
                state.payMenus  = [
                    {
                        "id": "0",
                        "point": "1",
                        "amount": 0.01,
                        "give": "0",
                        "productId": "com.hz.points.0.01"
                    },
                    {
                        "id": "1",
                        "point": "1",
                        "amount": 0,
                        "give": "0",
                        "productId": "com.hz.points.0"
                    },
                    {
                        "id": "2",
                        "point": "1",
                        "amount": state.customAmount,
                        "give": "0",
                        "productId": "com.hz.points."+state.customAmount
                    }
                ]
            }
        }
        return {
            ...toRefs(state),
            goTo,
            onPay,
            onSetting,
            formate,
            onSelectPayItem,
            getAmount,
            goBack,
            onInstall,
            rests,
            confirm
        };
    },
    components: {
        sHeader,
    },
};
</script>

<style lang="less" scoped>

.points {
    p {
        margin: 0px;
    }
}

.cur-points {
    padding: 15px 20px;
    font-size: 16px;
    text-align: left;
    img {
        height: 14px;
        margin-left: 5px;
        margin-right: 5px;
    }
    span {
        height: 18px;
    }
}

/deep/ .van-grid-item__content {
    padding: 0px 0px;
    border-radius: 5px;
    border: 1px solid #95a9ff;
    box-shadow: 0 0 3px #cccccc;
}

.pay-select {
    background-color: #ffffff;
    padding-bottom: 20px;
    .select-tips {
        height: 36px;
        background: #dfebf7;
        border: 1px solid #ebebeb;
        text-align: center;
        font-size: 16px;
        line-height: 36px;
    }

    .pay-box {
        margin-top: 20px;
        padding: 5px 0px;
        .pay-box-item {
            width: 100%;
            font-size: 15px;
            color: #615f64;
            display: flex;
            align-items: center;
            flex-flow: column;

            span {
                width: 100%;
                display: block;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
            }

            p {
                width: 101%;
                background-color: #95a9ff;
                text-align: center;
                line-height: 42px;
                color: #615f64;

                border-radius: 0px 0px 6px 6px;
                // border: 1px solid #95a9ff;
            }

            div {
                height: 40px;
                padding: 20px 5px;
                display: table;
            }
        }

        .pay-box-item-select {
            background: #ffffff;
            border-radius: 5px;
        }
    }
}
</style>